/**
 * Reference: https://currency.js.org/
 */
// import currency = require('currency.js');
import currency from 'currency.js';
import { floor } from 'lodash';

import { GlobalStore } from '@totopkg/shared-util-common';
import { LanguageStore } from '@totopkg/shared-util-language';

import { CURRENCY_SYMBOLS, TCurrencyCode } from './currency.type';

const LOCAL_CURRENCY_KEY = 'currency';

/**
 * if use force all format and formatCompact and formatCompactIf will return same format
 */
export type TCustomCurrencyFormat = {
  force?: 'non-compact' | 'compact';
  compactRatio?: number; // ** use for multiple amount of currency
};

export type TCurrencyConfig = {
  code?: TCurrencyCode;
  options?: currency.Options;
  /**
   * @important compactFormatOptions will **NOT** use when have custom compactRatio
   * @ref see **_format()** method
   */
  compactFormatOptions?: Intl.NumberFormatOptions;
  customFormat?: TCustomCurrencyFormat;
};

class _Currency {
  private static _instance: _Currency;
  private _formatOptions: currency.Options | undefined;
  private _compactFormatOptions: Intl.NumberFormatOptions | undefined;

  private _customFormat: TCustomCurrencyFormat | undefined;

  private constructor() {
    this._formatOptions = {
      symbol: CURRENCY_SYMBOLS.VND,
      precision: 0,
      separator: ',',
      pattern: '# !',
      negativePattern: '-# !'
    };

    this._compactFormatOptions = {
      compactDisplay: 'long'
    };
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new _Currency();
    }

    return this._instance;
  }

  public config = (config?: TCurrencyConfig) => {
    const { code = 'VND', options, compactFormatOptions, customFormat } = config || {};
    const symbol = CURRENCY_SYMBOLS[code];
    this._formatOptions = { ...this._formatOptions, symbol, ...options };
    this._compactFormatOptions = {
      ...this._compactFormatOptions,
      ...compactFormatOptions
    };
    this._customFormat = customFormat;
  };

  public add = (numbers: currency.Any[]) => {
    return numbers.reduce<currency>((prev, cur) => currency(prev).add(cur), currency(0));
  };

  public subtract = (number: currency.Any, subtract: currency.Any) => {
    return currency(number).subtract(subtract);
  };

  public multiply = (number: currency.Any, multiply: currency.Any) => {
    return currency(number).multiply(multiply);
  };

  public divide = (number: currency.Any, divide: currency.Any) => {
    return currency(number).divide(divide);
  };

  /**
   * Distribute takes the currency value, and tries to distribute the amount evenly.
   * Any extra cents left over from the distribution will be stacked onto the first sets of entries.
   */
  public distribute = (number: currency.Any, count: number) => {
    return currency(number).distribute(count);
  };

  public dollars = (number: currency.Any) => currency(number).dollars();

  public cents = (number: currency.Any) => currency(number).cents();

  private _format = (
    number: currency.Any | undefined,
    type?: TCustomCurrencyFormat['force'],
    options?: currency.Options | currency.Format | Intl.NumberFormatOptions
  ) => {
    if (!number) return '0';
    const { force, compactRatio = 1 } = this._customFormat || {};

    // ** alway use currencyJs format when compactRatio provided
    const _type: TCustomCurrencyFormat['force'] | undefined = compactRatio !== 1 ? 'non-compact' : force ?? type;

    const _number = floor(Currency.toCurrency(number).value * compactRatio, (options as any)?.precision ?? 0);

    switch (_type) {
      case 'non-compact': {
        return currency(_number || 0, {
          precision: (options as currency.Options)?.precision ?? this._formatOptions?.precision
        }).format({
          ...this._formatOptions,
          ...options
        });
      }

      case 'compact':
      default: {
        const formatter = Intl.NumberFormat(LanguageStore.languageSelector(), {
          notation: 'compact',
          ...this._compactFormatOptions,
          ...options
        });

        return formatter.format(currency(_number).value);
      }
    }
  };

  public format = (number: currency.Any | undefined, options?: currency.Options | currency.Format) => {
    return this._format(number, 'non-compact', options);
  };

  public formatCompact = (number: currency.Any | undefined, options?: Intl.NumberFormatOptions) => {
    return this._format(number, 'compact', options);
  };

  public formatCompactIf = (number: currency.Any | undefined, conditional?: (number: number) => boolean) => {
    if (conditional?.(Currency.toCurrency(number).value)) {
      return this.formatCompact(number);
    }

    return this.format(number);
  };

  public toString = (number: currency.Any) => currency(number).toString();

  public toCurrency = (number: currency.Any | undefined) => currency(number || 0);

  public toJSON = (number: currency.Any) => currency(number).toJSON();

  public get activeConfig() {
    return GlobalStore.dataSelector(LOCAL_CURRENCY_KEY, true, true);
  }

  public changeCurrency(config?: TCurrencyConfig) {
    GlobalStore.setDataAction(LOCAL_CURRENCY_KEY, config, true, true);
    window.location.reload();
  }
}

export const Currency = _Currency.instance();
